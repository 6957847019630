<template>
  <div class="endorsements">
    <div class="endorsement-wrapper">
      <div class="endorsement" :class="{ 'endorsement--blue': isBlue }" v-for="(endorsement, index) in visibleEndorsements" :key="index">
        <div class="image__container">
          <img :src="getImage(endorsement.photoUrl)" alt="Photo"/>
        </div>
        <h3>{{ endorsement.name }}</h3>
        <p>{{ endorsement.text }}</p>
      </div>
    </div>
    <div class="arrows">
      <button @click="prev" class="arrow-btn">←</button>
      <button @click="next" class="arrow-btn">→</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    endorsements: {
      type: Array,
      default: () => []
    },
    isBlue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  computed: {
    visibleEndorsements() {
      const start = this.currentIndex;
      const end = start + 3;
      return [...this.endorsements.slice(start, end), ...this.endorsements.slice(0, 3 - (end - this.endorsements.length))];
    }
  },
  methods: {
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.endorsements.length;
    },
    prev() {
      this.currentIndex = (this.currentIndex - 1 + this.endorsements.length) % this.endorsements.length;
    },
    getImage(path) {
      return require('@/' + path);
    }
  }
}
</script>

<style scoped>
.endorsements { position: relative; display: flex; align-items: stretch; overflow: hidden; }
.arrow-btn { background-color: #E0E0E0; border: none; cursor: pointer; font-size: 2rem; padding: 10px; border-radius: 50%; margin-left: 10px; }
.endorsement-wrapper { display: flex; overflow: hidden; }
.endorsement { flex: 0 0 calc(100% / 3 - 19px); background-color: #F2F4F8; display: flex; flex-direction: column; align-items: center; padding: 40px; margin-right: 20px; box-sizing: border-box; text-align: center; }
.endorsement--blue { background: #173C9B; }
.image__container { width: 150px; height: 150px; border-radius: 50%; overflow: hidden; display: flex; justify-content: center; align-items: center; }
.image__container img { max-width: 100%; display: block; object-fit: cover; }
.endorsement h3 { margin: 20px 0; color: #00258F; font-size: 2rem; font-weight: 600; }
.endorsement p { color: #00258F; }
.endorsement--blue h3, .endorsement--blue p { color: white; }
.arrows { position: absolute; bottom: 10px; right: 10px; display: flex; align-items: center; }

@media (max-width: 768px) {
  .endorsement { flex: 0 0 100%; margin-right: 0; padding: 40px; }
}
</style>
<template>
  <div>
    <div class="cover cover--small" :style="'background-image: url(' + require('@/assets/cover-contact.jpg') + ')'">
        <h1 class="cover__title">Event Registration</h1>
    </div>
    <div class="section wrapper">
      <template v-if="hasJoined">
        <h1 class="title">Thank you for registering for the event.</h1>      
        <p class="text">Together we can turn this country around! </p>            
        <p class="text">- Team Aaron</p>
      </template>
      <template v-else>
        <h1 class="title title--center">{{ eventBySource.title }}</h1>
        <div class="section text--center">
          <img class="events__icon" src="@/assets/map-pin.svg" alt=""/>
          <div>
            <p class="events__city">{{ eventBySource.city }}</p>
            <p class="events__address">{{ eventBySource.address }}</p>
            <p class="events__date">{{ eventBySource.date }}<br>{{ eventBySource.time }}</p>
          </div>
        </div>
        <p class="text text--center">Fill out the form below to register for the event and reserve your spot inside.</p>
        <div class="section box">
          <app-form :source="eventBySource.source" submit-text="Register for Event" :show-checkboxes="true" destination="/event?registered"></app-form>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AppForm from '@/components/utils/AppForm.vue'
import videosMixin from '@/videos-mixin.js'
import eventsMixin from '@/events-mixin.js';
export default {
	mixins: [videosMixin, eventsMixin],
   components: {
      AppForm
   },
  data () {
    return {

    }
  },
  computed: {
    hasJoined () {
      return this.$router.currentRoute.query.hasOwnProperty('registered');
    },
    getVideoComputed () {
      return this.getVideo(229128302367901);
    },
    currentEvent() {
      const eventKey = this.$route.query.source;
      return this.events.find(event => event.source === eventKey) || {};
    }
  },
  methods: {

  }
}
</script>

<style scoped>


</style>

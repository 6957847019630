export default {
   data() {
     return {
       events: [
         {
            source: 'event_campbell_river',
            title: 'Bring Back Common Sense Rally',
            city: 'Campbell River',
            address: '49 North Aircraft Hanger | 2200 Airport Dr, Campbell River',
            date: 'Wednesday, November 1st',
            time: '6:00PM'
         }
       ]
     };
   },
   computed: {
      eventBySource() {
        const sourceQuery = this.$route.query.source;
        return this.events.find(event => event.source === sourceQuery);
      }
   }
 };
<template>
  <div>
    <div class="cover cover--small" :style="'background-image: url(' + require('@/assets/cover-vote.jpg') + ')'">
        <h1 class="cover__title">Join Our Movement</h1>
    </div>

    <div class="section wrapper">
      <div class="section">
        <p class="text">On December 1st 2023, Aaron Gunn was officially acclaimed as the Conservative Party candidate for North Island-Powell River for the next federal election. To register your support for Aaron, and let him know that he can count on your vote, please fill out the form below.</p>
      </div>

      <div class="box">
        <!-- <div class="steps">
            <div class="step" :class="{ 'step--active': !isStepTwo, 'step--completed': isStepTwo }">
                <div class="step__circle">
                    <span v-if="isStepTwo" class="step__checkmark">✓</span>
                </div>
                <span class="step__count">Step 1</span>
                <span class="step__name">Register to Vote</span>
            </div>
            <div class="line"></div>
            <div class="step" :class="{ 'step--active': isStepTwo }">
                <div class="step__circle"></div>
                <span class="step__count">Step 2</span>
                <span class="step__name">Party Membership Check</span>
            </div>
        </div> -->
        <template v-if="!isStepTwo">
          <app-form source="post_cut_off" submit-text="Register" :quick-form="true" destination="/vote?stepTwo" :show-address="true" :show-checkboxes="true"></app-form>
        </template>
        <template v-else>
          <div class="section--center">
            <h2 v-if="!membershipBought" class="title title--blue title--small" style="margin: 30px 0 20px 0;">Thank you for your support<br><br>Together, we can defeat the Trudeau-NDP coalition and bring common sense back to Canada!</h2>
            
            <!-- <div v-if="isInRiding && !membershipBought" class="notice notice--good">
              <img class="notice__icon" src="@/assets/notice-good.svg" /> 
              <h2 class="title title--small"> Step 2: Become a Member of the Conservative Party</h2>
              <p><b>“The final step in becoming eligible to vote for me in the upcoming nomination race for North Island-Powell River, is to become a member of the Conservative Party of Canada ($15).</b>
              <br><br>Only party members are permitted to vote in these special contests - but please hurry! Time is running out to become a member and I could really use your support.” - Aaron </p>
              <br>
              <a class="button button--blue-dark button--large" href="https://donate.conservative.ca/en/membership/" target="_blank">Purchase a Membership</a>
              <br><br>
              <a class="button button--red button--large" href="#" @click="membershipBought = true">Click here once you have purchased your membership or if you are already a party member</a>
            </div>
            <div v-else-if="isInRiding && membershipBought" class="notice">
              <img class="notice__icon" src="@/assets/notice-good.svg" /> 
              <h2 class="title title--small">Thank you for registering to vote and purchasing a Conservative Party membership!</h2>
              <p><b>To confirm your support for Aaron, please forward your membership confirmation/receipt (that you should have received from the Conservative Party) to contact@aarongunn.ca so we can ensure you were registered successfully and inform you when the date/time/location of the vote has been set.</b></p>
            </div>
            <div v-else class="notice notice--bad">
              <img class="notice__icon" src="@/assets/notice-bad.svg"/>
              <p><b>Your postal code appears to not be in the riding of North Island-Powell River.</b>
              <br><br>If you are unsure whether you live in North Island-Powell River you can check using the Elections Canada website. Or, if you think you may have entered the incorrect postal code, you can return to the previous step, <a class="link" href="javascript:history.back()">here</a></p>
              <br>
              <a class="button button--red button--large" href="https://www.elections.ca/scripts/vis/FindED" target="_blank">Verify Postal Code</a>
            </div> -->
          </div>
        </template>
      </div>
    
      <!-- <ul class="qna" v-if="isStepTwo && isInRiding">
        <li class="qna__item">
          <h1 class="qna__question">→ Already a Member?</h1>
          <p class="text">If you’re already a member of the Conservative Party, our team will be in touch with voting dates, and locations, as they become available. In the meantime, don’t forget to spread the word to your family, neighbors and friends. Thank you so much for your support!</p>
        </li>
        <li class="qna__item">
          <h1 class="qna__question">→ Not sure if you’re a member or if your membership has expired?</h1>
          <p class="text">To check to see if you’re a member of the Conservative Party, or if your membership has expired, <a class="link" href="https://www.conservative.ca/membership-check/" target="_blank">click here</a>.</p>
        </li>
        <li class="qna__item">
          <h1 class="qna__question">→ What happens next?</h1>
          <p class="text">The Conservative Party is expected to announce the official voting date(s) for North Island-Powell River in the weeks and months ahead. As soon our team is informed of the voting times and locations (the vote will be in-person) we will be sure to let you know.</p>
        </li>
      </ul> -->
    </div>
</div>
</template>

<script>
import AppForm from '@/components/utils/AppForm.vue'
export default {
  components: {
    AppForm
  },
  created() {

  },
  data () {
    return {
      membershipBought: false
    }
  },
  computed: {
		isStepTwo () {
		  return this.$router.currentRoute.query.hasOwnProperty('stepTwo');
		},
 		isInRiding () {
		  return this.$router.currentRoute.query.hasOwnProperty('inRiding');
		} 
  },
  methods: {

  }
}
</script>

<style scoped>
.steps { display: flex; width: 50%; margin: auto; padding: 20px 0 30px; }
.step { display: flex; flex-direction: column; align-items: center; margin: 0 10px; width: 0; overflow: visible; text-wrap: nowrap; z-index: 2; }
.line { flex-grow: 1; height: 2px; background-color: #DCDCDC; position: relative; margin-top: calc(25px / 2); z-index: 1; }
.step__circle { width: 25px; height: 25px; box-sizing: border-box; border-radius: 50%; background: white; border: 8px solid #DCDCDC; display: flex; align-items: center; justify-content: center; margin-bottom: 5px; }
.step--active .step__circle { border-color:#002B7A; }
.step__checkmark { display: none; font-size: 14px; color: white; }
.step--completed .step__circle { border: none; background-color: #002B7A; }
.step--completed .step__checkmark { display: block; }
.step__count { font-weight: bold; padding: 10px 0 5px; }

.notice { max-width: 1000px; margin:auto; border-radius: 12px; background: white; padding: 30px 20px 20px 60px; position: relative; text-align: left; }
.notice__icon  { position: absolute; width: 20px; height: 20px; left: 20px; top: 30px; }
.notice--good { border: 1px solid #80ACFF; color: #00256B; }
.notice--bad { border: 1px solid #FDA29B; color: #B42318; }

@media (max-width: 1100px) {

}

@media (max-width: 768px) {
  .steps { width: calc(100% - 157px); padding-bottom: 0; }
}

@media (max-width: 526px) {

}
</style>

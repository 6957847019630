<template>
<div>
	<div class="cover" :style="'background-image: url(' + require('@/assets/cover-home.jpg') + ');'">
		<div class="wrapper cover__content">
         <div class="grid">
            <div class="grid__item">
               <h1 class="cover__title">Help Elect<br>Aaron Gunn</h1>
               <h2 class="cover__subtitle">As the Next MP for North Island-Powell River</h2>
               <app-form source="post_cut_off" submit-text="Join Our Movement" :quick-form="true" destination="/vote?stepTwo"></app-form>
            </div>
            <div class="grid__item">
               <img src="@/assets/home-cover-image.jpg" alt=""/>
            </div>
         </div>
		</div>
	</div>
   <ul class="action-bar">
      <li class="action-bar__item">
         <a class="action-bar__link" href="/vote">
            <img class="action-bar__icon" src="@/assets/action-bar-vote.svg" alt="How to Vote"/>
            <span class="action-bar__label">How to Vote</span>
         </a>
      </li>
      <li class="action-bar__item">
         <a class="action-bar__link" href="#speak">
            <img class="action-bar__icon" src="@/assets/action-bar-call.svg" alt="Speak with Aaron"/>
            <span class="action-bar__label">Speak with Aaron</span>
         </a>
      </li>
      <li class="action-bar__item">
         <a class="action-bar__link" target="_blank" href="https://www.niprconservatives.ca/donate">
            <img class="action-bar__icon" src="@/assets/action-bar-donation.svg" alt="Donation"/>
            <span class="action-bar__label">Make a Donation</span>
         </a>
      </li>
   </ul>

   <!-- <div class="section section--blue">
      <div class="wrapper">
         <h1 class="title title--white title--spaced">Upcoming Events</h1>
         <ul class="events">
         <li class="events__item" v-for="event in events" :key="event.source">
            <div class="events__content">
               <h1 class="title title--small">{{ event.title }}</h1>
               <div class="events__location">
               <img class="events__icon" src="@/assets/map-pin.svg" alt="" />
               <div>
                  <p class="events__city">{{ event.city }}</p>
                  <p class="events__address">{{ event.address }}</p>
               </div>
               </div>
            </div>
            <div class="events__content">
               <p class="events__date">{{ event.date }}<br>{{ event.time }}</p>
               <a :href="'/event?source=' + event.source" class="button button--red" style="margin-top: 20px">Register</a>
            </div>
         </li>
         </ul>
      </div>
   </div> -->

   <div class="introduction section">
      <h1 class="introduction__title">Bring Back Common Sense</h1>
      <div v-if="getVideoComputed" class="video">
         <img :src="getVideoComputed.thumbnailUrl" class="video__image"/>
         <a href="#" @click.prevent="toggleYoutubeVideoPopup(getVideoComputed.id)">
            <p class="video__play play-button" @click.prevent="toggleYoutubeVideoPopup(video.id)">Watch Video</p>
         </a>
      </div>
   </div>

   <div class="wrapper section">
      <h1 class="title">RUNNING TO DEFEAT THE TRUDEAU/NDP COALITION</h1>
      <p class="text">Whether it&#39;s the cost of living, a collapsing health care system, or deteriorating public safety, in Justin Trudeau’s Canada, everything seems to be getting worse.</p>
      <p class="text">All driven by an ideological government in Ottawa that has left Canadians divided, our economy inverted and our entire resource sector hanging by a thread.</p>
      <div class="grid grid--center">
         <div class="grid__item">
            <p class="text text--bold">I'm tired of watching our country head in the wrong direction.</p>
            <p class="text">Since meeting (and interviewing) Pierre back in 2021, I&#39;ve gotten to know the man I believe will be Canada&#39;s next Prime Minister. He has the vision, drive and, most importantly, the courage to stand up to Ottawa&#39;s elites and finally defeat Justin Trudeau.</p>
            <p class="text text--bold">It's time to turn Canada around.</p>
            <p class="text">I believe in putting taxpayers first, respecting our constitutional rights and supporting the hard-working men and women who built (and continue to build) this country every single day.</p>
            <p class="text">Over the past several years, my team and I have produced <a href="https://www.facebook.com/watch/?v=1431301910953812" target="_blank" class="link">groundbreaking documentaries</a> that seek to uncover the truth on the issues that matter to Canadians.</p>
            <p class="text">While I am proud of my work investigating these issues, I don&#39;t just want to report on them;</p>
            <p class="text text--bold">I want to do something about them.</p>
            <p class="text">But to restore our country, I need your support and humbly ask for your vote.</p>
            <p class="text">Sincerely,</p>
            <img src="@/assets/signature.png" alt="Signature" style="width: 225px"/>
         </div>
         <div class="grid__item">
            <img src="@/assets/home-aaron-pierre-poilievre.jpg" alt="Aaron and Pierre Poilievre"/>
         </div>
      </div>
      <a class="button button--large button--blue-dark" style="width: 100%; margin-top: 20px;" href="/meet-aaron">Learn More About Aaron</a>
   </div>

   <div id="speak" class="section section--overlay section--blue section--center" :style="'background-image: url(' + require('@/assets/jumbotron-call.jpg') + ')'">
      <div class="wrapper">
         <h1 class="title title--white title--spaced">Schedule a Call with Aaron Gunn</h1>
         <p class="text text--white">Fill out the form below to schedule a call with Aaron to discuss the issues that matter to you.</p>
         <app-form source="schedule_call" submit-text="Schedule Call" :show-best-time-to-call="true" :quick-form="true" destination="/contact?sent"></app-form>
      </div>
   </div>

   <div class="box box--borderless box--blue">
      <div class="wrapper box">
         <div class="grid">
            <div class="grid__item">
               <img src="@/assets/home-kermit-dahl.jpg" alt="Aaron and Pierre Poilievre"/>
            </div>
            <div class="grid__item">
               <h1 class="title" style="margin-top: 50px; margin-bottom: 20px;">A Message From the Mayor:</h1>
               <p class="text">As Mayor of Campbell River, I can think of no better representative to take on the Trudeau-NDP coalition than Aaron Gunn. As a municipal government, we need strong advocates at the federal level to fight for us and our needs. </p>
               <p class="text">In a time when politics can be divisive, Aaron stands out as a voice of reason, advocating for policies that benefit all residents of our community.</p>
               <p class="text">I wholeheartedly endorse Aaron and look forward to working with him as our new Member of Parliament!</p>
               <p class="text">Yours,</p>
               <p class="text"><span class="text--bold">Kermit Dahl;</span><br>Mayor of Campbell River</p>
            </div>
         </div>
      </div>
   </div>

   <div class="wrapper section">
      <h1 class="title">Endorsements from the Riding:</h1>
      <endorsement-carousel :endorsements="endorsementsRiding"></endorsement-carousel>
   </div>

   <div class="box box--blue box--borderless">
      <div class="wrapper section">
         <h1 class="title title--white">Endorsements from across Canada</h1>
         <endorsement-carousel :is-blue="true" :endorsements="endorsementsCanada"></endorsement-carousel>
      </div>
   </div>

   <div class="jumbotron jumbotron--large" :style="'background-image: url(' + require('@/assets/jumbotron.jpg') + ')'"></div>

   <div class="section section--overlay section--center" :style="'background-image: url(' + require('@/assets/cover-home.jpg') + ');'">
      <div class="wrapper">
         <h1 class="title title--white title--spaced">Join the Movement to Bring Common Sense Back to Canada</h1>
         <app-form source="post_cut_off" submit-text="Register to Vote" :quick-form="true" destination="/vote?stepTwo"></app-form>
      </div>
   </div>

 </div>
</template>

<script>
import AppForm from '@/components/utils/AppForm.vue'
import EndorsementCarousel from '@/components/utils/EndorsementCarousel.vue'
import videosMixin from '@/videos-mixin.js'
import eventsMixin from '@/events-mixin.js';
export default {
	mixins: [videosMixin, eventsMixin],
   components: {
      AppForm,
      EndorsementCarousel
   },
	created () {

	},
	data() {
		return {
         endorsementsCanada: [{
            photoUrl: "assets/endorsement-bob.jpg",
            name: "Bob Zimmer | Member of Parliament | Prince George—Peace River—Northern Rockies",
            text: "“Aaron Gunn is exactly the type of advocate North Island – Powell River deserves. Whether it’s being a voice for B.C. forestry, safer communities, law-abiding firearms owners or a defender of free speech, I am confident Aaron will relentlessly fight for the people of his riding.”"
         },
         {
            photoUrl: "assets/endorsement-jj.jpg",
            name: "J.J. McCullough | YouTuber & Columnist",
            text: "“Whether it's drugs, crime, free speech, or Canadian energy, Aaron has been a consistent, principled fighter for what's right. The stakes are high in this election and we need candidates who see the problems of this country clearly — and know what needs to be done to fix them.”"
         },         
         {
            photoUrl: "assets/endorsement-rodney.jpg",
            name: "Rodney Stafford | Father of Tori Stafford",
            text: "“We need honest, truth telling politicians who work hard for this country. I fully endorse Aaron's representation on behalf of those who cannot speak for themselves, and for those of us caught in the this horrific system we call justice.”"
         },
         {
            photoUrl: "assets/endorsement-bonnie.jpg",
            name: "Bonnie George, Wet’suwet’en Matriarch",
            text: "“I would like to thank Aaron Gunn and his team for the opportunity to share the truth of the Wet’suwet’en.  The projects that we worked together which has given us courage and confidence to share our truth during challenging times for our nation, there are no words that say enough how grateful we are.”"
         },
         {
            photoUrl: "assets/endorsement-john.jpg",
            name: "John Martin, former MLA for Chilliwack",
            text: "“With proud enthusiasm, I am endorsing Aaron Gunn to be the Conservative candidate for North Island-Powell River. Aaron will never back down from standing up for what is right, even when it is difficult, and will put the best interests of the good people of North Island-Powell River first. Without reservation, he has my full support”"
         }],
         endorsementsRiding: [
         {
            photoUrl: "assets/endorsement-andrew.jpg",
            name: "Andrew Rice | Courtenay Resident",
            text: "“The North Island - Powell River riding has been in great need of conservative representation,  I believe with Aaron's knowledge and beliefs that he will be a great candidate for this riding.”"
         },
         {
            photoUrl: "assets/endorsement-kohen.jpg",
            name: "Kohen Gilkin | City Councillor, Sayward",
            text: "“I am endorsing Aaron Gunn to run as our Conservative MP in the next federal election. Aaron Gunn has the common sense approach we need to end the liberal/NDP divide in our country.”"
         },
         {
            photoUrl: "assets/endorsement-brennan.jpg",
            name: "Brennan Day | Comox Resident",
            text: "“Having followed Aaron's political career and the open and honest discussions he brings to the table through his documentary work, I can without reservation endorse his nomination for the North Island-Powell River Conservatives. We need people who are not afraid to tackle tough issues in a thorough and thoughtful way, and I believe Aaron will represent our riding honestly and put in the hard work required to give us back our voice in Ottawa.”"
         },
         {
            photoUrl: "assets/endorsement-summers.jpg",
            name: "David Summers | Serengeti Fishing Charters | Port Hardy Resident",
            text: "“As a north island business owner, I can’t think of a better person to represent our important resource sector jobs than Aaron. Not only does he understand our industry’s, but he cares deeply about their success. On top of that, he is just a great guy as well. Look forward to seeing him as our MP.”"
         },
         {
            photoUrl: "assets/endorsement-mulroney.jpg",
            name: "Robert Mulroney | Courtenay Resident",
            text: "“ I have thoroughly enjoyed Aaron's politics explained series I believe Aaron will make an excellent MP for Canada 🇨🇦.”"
         },
         {
            photoUrl: "assets/endorsement-ponting.jpg",
            name: "Mark Ponting | Ponting Contracting | Comox Resident",
            text: "“Aaron has demonstrated a deep understanding of the issues that matter most to our community. His common sense approach is a breath of fresh air and something that has been lacking in the last few years in politics. I’ve had the privilege of interacting with Aaron personally and the dedication and commitment to our community is evident. He listens to our concerns and is proactive in finding solutions. I encourage everyone in our riding to join me in supporting Aaron Gunn and casting their vote for a brighter future.”"
         },
         {
            photoUrl: "assets/endorsement-damon.jpg",
            name: "Damon Scrase | Courtenay Resident",
            text: "“North Island-Powell River has been underserved in Ottawa for many years. As a commercial fisherman, I have confidence in Aaron Gunn's passionate advocacy for our industry. He will stand up for the dedicated individuals who depend on this sector, a vital backbone of our coastal communities. I endorse Aaron because he stands firmly with us.”"
         },       
         {
            photoUrl: "assets/endorsement-hynd.jpg",
            name: "Garry and Richelle Hynds | Comox Residents",
            text: "“ Aaron’s strong commitment to defending free speech, cracking down on crime (and not law-abiding Canadians), scrapping the carbon tax, affordable homes, a treatment-recovery approach and, particularly important to North Island-Powell River, standing up for resource workers all makes Aaron the obvious choice for defeating the Trudeau/NDP Coalition. For our future and that of our children, please join us in supporting Aaron Gunn’s nomination to be the next Conservative candidate for North Island-Powell River.”"
         },
         {
            photoUrl: "assets/endorsement-grant.jpg",
            name: "Grant Woods | Video Editor, Politics Explained | Courtenay Resident",
            text: "“I’ve worked with Aaron for years and I know him to be a true defender of principled and responsible government. Through countless hours filming and editing our documentaries together, I have been privileged to witness his grit, hard work and dedication to bettering our country. I couldn't imagine a better representative for the riding we both call home.”"
         },
         {
            photoUrl: "assets/endorsement-stan.jpg",
            name: "Stan Zuke | Campbell River Resident",
            text: "“As a retired resource manager on Vancouver Island, I have seen our beloved forest industry go through many economic cycles…  but I have never seen our industry in such a state of uncertainty as it is today. Since meeting Aaron Gunn, I have a strong sense of optimism that gives me hope for the future both in industry and our daily lives as he shares our common sense values.”"
         },
         {
            photoUrl: "assets/endorsement-depins.jpg",
            name: "Susan Despins | Campbell River Resident",
            text: "“As a citizen within the riding of North Island/Powell River, I confidently endorse Aaron Gunn to run as our Conservative MP in the next federal election. I have seen and experienced his dedication to causes that affect us all. He is available and approachable and has never failed to return a call or text when I have reached out. Liberals are trying to ban guns in Canada. This is one Gunn that should not be stopped!”"
         },
         {
            photoUrl: "assets/endorsement-zeke.jpg",
            name: "Zeke and Angy Pellegrin | Campbell River Residents",
            text: "“Through getting to know Aaron Gunn over this past year and seeing the determination, hard work and common sense that he applies to all of his projects, my wife and I feel that Aaron has what it takes to make a positive change to not only our community but any community he represents. That’s why we support Aaron Gunn for the Conservative riding for North Vancouver Island and Powell River.”"
         },
         {
            photoUrl: "assets/endorsement-mavin.jpg",
            name: "Doug Mavin | Powell River Resident",
            text: "“Through his documentaries, Aaron Gunn has proven to be a steadfast defender of the commercial fishing industry. As a Member of Parliament for our riding, I am confident he will fight for us in Ottawa and bring back common sense to government.”"
         }]
		}
	},
	methods: {

	},
	computed: {
      getVideoComputed () {
         return this.getVideo("3i7fxSWCRdk");
      }
	}
}
</script>

<style scoped>
.action-bar { list-style: none; display: flex; flex-wrap: wrap; background: #062165; }
.action-bar__item { flex: calc(100% / 3) 0 0; }
.action-bar__link { display: flex; flex-direction: column; align-items: center; border: 1px solid white; box-sizing: border-box; padding-bottom: 20px; }
.action-bar__link:hover { background: #05184A; }
.action-bar__icon { width: 150px; }
.action-bar__label { color: white; text-transform: uppercase; font-size: 2em; }

.introduction { background: linear-gradient(to bottom, rgba(6, 33, 101, 0.90) 50%, transparent 50%); display: flex; flex-direction: column; align-items: center; }
.introduction__title { color: white; text-transform: uppercase; font-size: 2.5em; padding: 40px 0 40px; }

.politics-explained-text { width: 100%; max-width: 500px; margin-top: 40px; }

@media (max-width: 1100px) {
   .video { height: 200px; }
}

@media (max-width: 768px) {
   .action-bar { flex-direction: column; }
   .action-bar__item { flex-basis: 100%; }
   .introduction  { text-align: center; padding-bottom: 0; }
}

@media (max-width: 526px) {

}

</style>

<template>
<div>
	<div class="cover cover--small" :style="'background-image: url(' + require('@/assets/cover-contact.jpg') + ')'">
		<h1 class="cover__title">Contact</h1>
	</div>
	<div class="section wrapper">
		<template v-if="isSent">
			<h1 class="title">Message Successfully Sent</h1>
			<p class="text text--bold">Aaron or someone from his team will get back to you shortly. Thank you for your interest!</p>
		</template>
		<template v-else>
			<h1 class="title title--small">Interested in learning more about our campaign? Having Aaron come and speak to your group, or at your event?</h1>
			<p class="text">Simply reach out by completing the form below and we’ll be sure to be in touch as soon as possible!</p>
			<div class="section box">
				<app-form source="contact" submit-text="Send Message" :show-message="true" :show-checkboxes="true" destination="/contact?sent" ></app-form>
			</div>
		</template>
	</div>
</div>
</template>

<script>
import AppForm from '@/components/utils/AppForm.vue'
export default {
   components: {
      AppForm
   },
	computed: {
		isSent () {
			return this.$router.currentRoute.query.hasOwnProperty('sent');
		}
	}
}
</script>

<style scoped>


</style>
